<template>
  <div class="common-wrap">
    <headNav active="6"></headNav>
    <div class="common-contents">
      <div class="banner">
        <img
          src="@/assets/version2/joinUs-1.png"
          alt=""
          srcset=""
          class="img"
          v-if="$i18n.locale == 'zh'"
        />
        <img
          src="@/assets/version2/joinUsEn-1.png"
          alt=""
          srcset=""
          class="img"
          v-else
        />
      </div>
      <div class="contents tal">
        <div class="dot dot-1"></div>
        <div class="dot dot-2"></div>
        <!-- 乐团简介 -->
        <main-title
          :name="$t('join.name')"
          width="234"
          height="27"
          :src="require('../assets/version2/joinUs-en-1.png')"
        ></main-title>
        <div class="w1200">
          <div class="le-2 f16 t-c2 f-w-3">
            <div class="mt-41" v-html="$t('introductory.title1')"></div>
            <!-- <div class="mt-21">{{ $t("join.introduction2") }}</div> -->
          </div>
          <!-- <img src="@/assets/version2/joinUs-2.png" class="mt-36 wd" /> -->
          <video
            class="mt-36 wd"
            controls
            src="https://c.vod.suzhousco.com/sv/52261218-17e47235ee6/52261218-17e47235ee6.mp4"
          >
            your browser does not support the video tag
          </video>
        
        </div>
        <!-- 乐团职能 -->
        <main-title
          :name="$t('join.orchestra')"
          width="305"
          height="37"
          :src="require('../assets/version2/joinUs-en-2.png')"
        ></main-title>
        <div class="w1200">
          <div class="mt-33 le-2 f16 t-c2 f-w-3">
            {{ $t("join.introduction3") }}
          </div>
        </div>
        <!-- 招聘信息 -->
        <div>
          <main-title
            :name="$t('join.recruitment')"
            width="159"
            height="28"
            :src="require('../assets/version2/joinUs-en-3.png')"
          ></main-title>
          <div class="w1200">
            <img src="@/assets/version2/joinUs-3.png" class="mt-53 wd" v-if="$i18n.locale == 'zh'" />
            <img src="@/assets/version2/joinUsEn-3.png" class="mt-53 wd" v-else />
          </div>
        </div>
        <!-- 考试信息 -->
        <main-title
          :name="$t('join.examInformation')"
          width="247"
          height="36"
          :src="require('../assets/version2/joinUs-en-4.png')"
        ></main-title>
        <div class="w1200 mt-32">
          <div>
            <div class="t-c1 f-24 f-w-4 mt-15">{{ $t("join.ei1") }}</div>
            <div class="t-c2 f-16 f-w-4 mt-15">{{ $t("join.ei2") }}</div>
            <div class="t-c2 f-16 f-w-3 mt-15">{{ $t("join.ei3") }}</div>
            <div class="t-c2 f-16 f-w-4 mt-15">{{ $t("join.ei4") }}</div>
            <div class="t-c2 f-16 f-w-3">
              <div class="mt-15">{{ $t("join.ei5") }}</div>
              <div class="mt-15">{{ $t("join.ei6") }}</div>
              <div class="mt-15">{{ $t("join.ei7") }}</div>
              <div class="mt-15">{{ $t("join.ei8") }}</div>
            </div>
          </div>
          <div v-if="$i18n.locale == 'zh'">
            <div class="t-c1 f-24 f-w-4 mt-32">{{ $t("join.rg1") }}</div>
            <div class="t-c2 f-16 f-w-4 mt-15">{{ $t("join.rg2") }}</div>
            <div class="t-c2 f-16 f-w-3 mt-15">{{ $t("join.rg3") }}</div>
            <div class="t-c2 f-16 f-w-4 mt-15">{{ $t("join.rg4") }}</div>
            <div class="t-c2 f-16 f-w-3">
              <div class="mt-15">
                <a href="https://suco.oss-cn-hangzhou.aliyuncs.com/files/%E8%8B%8F%E5%B7%9E%E6%B0%91%E6%97%8F%E7%AE%A1%E5%BC%A6%E4%B9%90%E5%9B%A2%E6%8A%A5%E5%90%8D%E8%A1%A8.doc" target="_blank">
                {{$t('join.rg7')}}</a>
              </div>
            </div>
            <div class="t-c2 f-16 f-w-4 mt-15">{{ $t("join.rg6") }}</div>
            <div class="t-c2 f-16 f-w-3 mt-15">{{ $t("join.rg61") }}</div>
          </div>
          <div v-else>
            <div class="t-c1 f-24 f-w-4 mt-32">{{ $t("join.rg1") }}</div>
            <div class="t-c2 f-16 f-w-4 mt-15">{{ $t("join.rg2") }}</div>
            <div class="t-c2 f-16 f-w-3 mt-15">{{ $t("join.rg3") }}</div>
            <div class="t-c2 f-16 f-w-4 mt-15">{{ $t("join.rg4") }}</div>
            <div class="t-c2 f-16 f-w-3">
              <div class="mt-15" v-html="$t('join.rg5')"></div>
              <div class="mt-15">
                <span v-html="$t('join.rg6')"></span>
                <a href="https://suco.oss-cn-hangzhou.aliyuncs.com/files/%E8%8B%8F%E5%B7%9E%E6%B0%91%E6%97%8F%E7%AE%A1%E5%BC%A6%E4%B9%90%E5%9B%A2%E6%8A%A5%E5%90%8D%E8%A1%A8.doc" target="_blank">
                {{$t('join.rg7')}}</a>
              </div>
            </div>
          </div>
          <div>
            <div class="t-c1 f-24 f-w-4 mt-32">{{ $t("join.se1") }}</div>
            <div class="t-c2 f-16 f-w-3">
              <div class="mt-15">{{ $t("join.se2") }}</div>
              <div class="mt-15">{{ $t("join.se3") }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footNav />
  </div>
</template>

<script>
import headNav from "../components/header2.vue";
import footNav from "../components/footer2.vue";
import mainTitle from "../components/main-title/index.vue";
export default {
  components: { headNav, footNav, mainTitle }
};
</script>

<style scoped lang="scss">
.common-contents {
  background-color: #f2f6f7;
  .banner {
    .img {
      width: 100%;
      max-height: 682px;
    }
  }
  .contents {
    position: relative;
    padding-bottom: 311px;
    .dot {
      position: absolute;
      z-index: 99;
      background-image: url("../assets/version2/other-13.png");
      background-size: 100% 100%;
    }
    .dot-1 {
      width: 255px;
      height: 438px;
      top: 348px;
      left: 0;
    }
    .dot-2 {
      width: 393px;
      height: 834px;
      bottom: 569px;
      right: 0;
      transform: rotate(180deg);
    }
  }
}
</style>