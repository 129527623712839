<template>
  <div class="common-title-wrap">
    <div class="line"></div>
    <div class="text-wrap">
      <div class="text-main flex">
        <div class="left"></div>
        <div class="right">
          <div class="text">{{name}}</div>
          <img
            :src="src"
            alt=""
            class="en-img"
            :style="{width: width + 'px', height: height + 'px'}"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    props: {
        name: {
            type: String,
            default: ''
        },
        // 英文图标宽高
        width: {
            type: String,
            default: '0'
        },
        height: {
            type: String,
            default: '0'
        },
        src:  {
            type: String,
            default: ''
        },
    }
}
</script>

<style scoped lang="scss">
.common-title-wrap {
  margin-top: 136px;
  position: relative;
  .line {
    width: 100%;
    border-bottom: 1px solid #d1d4d4;
  }
  .text-wrap {
    width: 1300px;
    margin: 0 auto;
    margin-top: -50px;
    text-align: left;
    .text-main {
      display: inline-block;
      background: #f2f6f7;
    }
    .left {
      width: 10px;
      height: 105px;
      background: #b49a71;
      display: inline-block;
      vertical-align: middle;
    }
    .right {
      max-width: 1290px;
      display: inline-block;
      vertical-align: middle;
      padding: 0 42px;
      height: 90px;
      position: relative;
      .text {
        font-size: 36px;
        font-weight: 700;
        color: #4d4d4d;
        letter-spacing: 2px;
      }
      .en-img {
        position: absolute;
        left: 42px;
        z-index: 10;
        bottom: 10px;
      }
    }
  }
}
</style>