import { render, staticRenderFns } from "./13-join2.vue?vue&type=template&id=808a5c38&scoped=true"
import script from "./13-join2.vue?vue&type=script&lang=js"
export * from "./13-join2.vue?vue&type=script&lang=js"
import style0 from "./13-join2.vue?vue&type=style&index=0&id=808a5c38&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "808a5c38",
  null
  
)

export default component.exports